document.addEventListener("DOMContentLoaded", () => {
  const containerEl = document.querySelector(
    "#contains-the-world",
  ) as HTMLSpanElement;
  const worldEl = document.querySelector("#the-world") as HTMLDivElement;
  const spriteWidth = worldEl.getBoundingClientRect().width;
  const initForce = 1;
  const maxVelocity = 100;
  let force = initForce;
  const accel = 0.1;
  const degreesInRotation = 360;
  const frames = 12;
  const degreesInFrame = degreesInRotation / frames;
  const fps = 24;
  const tickTime = 1000 / fps;

  // targets 3 sprite frames per sec
  const steadyIncrease = (degreesInFrame * 3) / fps;

  const xOffset = (frame: number) => {
    return frame * spriteWidth;
  };

  let rotation = 0; // in degrees
  let frame = 0;
  let velocity = 0;

  const physicsTime = 3_000;
  const esplodeTime = 100_000;

  const isTimeFor = (ts, tTimer, tgtTime) => {
    return ts - tTimer > tgtTime;
  };

  const drag = (v: number) => Math.max(v / 10, 0.005 * v * v);

  const updatePosition = (v = velocity) => {
    rotation = (rotation + v) % degreesInRotation;
    frame = Math.floor(rotation / degreesInFrame);
  };

  const updateVelocity = (deltaV: number) => {
    velocity = Math.min(
      maxVelocity,
      velocity + deltaV - drag(Math.round(velocity)),
    );
  };

  const physicsTick = (isHovering: boolean) => {
    updateVelocity(isHovering ? force : 0);
    updatePosition();

    if (isHovering) {
      force += accel;
    } else {
      force = initForce;
    }

    if (velocity > 45) {
      shakeTick();
    } else {
      resetShake();
    }

    updateFrame();
  };

  const steadyTick = (isHovering: boolean) => {
    if (isHovering) {
      updatePosition(steadyIncrease);
      updateFrame();
    }
  };

  const updateFrame = () => {
    worldEl.style.backgroundPositionX = `${xOffset(frame)}px`;
  };

  const rBigPos = () =>
    (Math.random() > 0.5 ? 1 : -1) * (2000 + Math.random() * 2000);

  const esplode = () => {
    console.log("boom!");
    const pos = worldEl.getBoundingClientRect();
    worldEl.style.position = "fixed";
    worldEl.style.top = pos.y + "px";
    worldEl.style.left = pos.x + "px";
    const dest = `translate(${rBigPos()}px, ${rBigPos()}px) scale(${Math.random() > 0.5 ? 20 : 0.001})`;
    worldEl.style.transform = dest;
  };

  let shakeDir = 1;
  let shakeTimer: number | null = null;
  const shakeTick = () => {
    if (!shakeTimer) {
      shakeTimer = setTimeout(() => {
        esplode();
      }, esplodeTime);
    }
    shakeDir *= -1;
    worldEl.style.translate = `${shakeDir}px`;
  };

  const resetShake = () => {
    if (shakeTimer !== null) {
      clearTimeout(shakeTimer);
    }
    worldEl.style.translate = "";
  };

  let t = -Infinity;
  let tPhysics: number | null = null;
  let inPhysics = false;
  let doAnimation = false;
  const animate = (timestamp: DOMHighResTimeStamp = 0) => {
    if (!doAnimation) {
      return;
    }

    if (!tPhysics) {
      tPhysics = timestamp;
    }
    if (isTimeFor(timestamp, t, tickTime)) {
      t = timestamp;

      const worldHovering = worldEl.matches(":hover");
      const textHovering = containerEl.matches(":hover");
      const isHovering = worldHovering || textHovering;

      // physics timer only run while hovering on world.
      if (!worldHovering) {
        tPhysics = timestamp;
      }

      inPhysics =
        (worldHovering && isTimeFor(timestamp, tPhysics, physicsTime)) ||
        velocity > 0.5;

      // if (!inPhysics && !isHovering) {
      //   doAnimation = false;
      //   return;
      // }

      if (!inPhysics) {
        steadyTick(true);
      } else {
        physicsTick(worldHovering);
      }
    }
    requestAnimationFrame(animate);
  };

  doAnimation = true;
  animate();

  containerEl.addEventListener("mouseenter", () => {
    if (!doAnimation) {
      doAnimation = true;
      animate();
    }
  });
});
